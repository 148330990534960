import {
  container,
  mlAuto,
  mrAuto,
  title,
  main,
  mainRaised
} from "assets/jss/material-kit-pro-react.jsx";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.jsx";

const mediaCenter = function(theme) {
  return {
    container,
    mlAuto,
    mrAuto,
    title,
    main,
    mainRaised,
    ...teamsStyle,
    card: {
      boxShadow: `none`,
      border: `solid 1px #eee`,
      "&:hover": {
        boxShadow: `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4)`
      }
    },
    awardHeader1: {
      [theme.breakpoints.down(`sm`)]: {
        textAlign: `center`,
        paddingTop: `10px`,
        paddingBottom: `10px`
      }
    },
    modalAwards: {
      maxWidth: `700px`,
      maxHeight: `800px`
    },
    mapForm: {
      position: `absolute`,
      top: `-3%`,
      width: `90%`,
      margin: `0 auto`,
      left: `0`,
      right: `0`
    },
    cpaddingLeft:{
      paddingLeft:'3%'
    },
    cnonpaddingLeft:{
      paddingLeft:'2%'
    },
    RestatedFinancePosition:{
      paddingBottom:'2.5%',
      "@media (max-width: 576px)": {
        paddingBottom:'7.3%'
      },
    
    },
    listTableContainer: {
      backgroundColor: `rgb(229, 227, 223)`,
      height: `100vh`
    },
    paddingRemove:{
    paddingLeft:'0px !important',
    paddingRight:'0px !important'
    },
    paddingRemoveRegulatory:{
      paddingLeft:'0px !important',
      paddingRight:'0px !important',
      "& div": {
        marginBottom:'0px !important'
      }
      },
      rmmarginRegulatory:{
        "& div": {
          marginBottom:'0px !important'
        }
        },
    marginRemoveRegulatory:{
      marginLeft:'0px !important',
      marginRight:'0px !important',
      "& div": {
        marginBottom:'0px !important'
      }
    },
    anchorStyle:{
      padding:'1px !important',
      paddingLeft: `2% !important`,
      display:'block !important'
    },
    listSearchContainer: {
      paddingTop: `50px`,
      overflowY: `hidden`,
      position: `relative`,
      "@media (max-width: 570px)": {
        paddingTop: `90px`
      }
    },
    paginationScroll: {
      overflow: `auto`
    },
    analysemeet:{
      marginTop: "2.5%",
      marginLeft: "-45%",
      "@media (max-width: 1030px)": {
        marginLeft: "-42%",
      },
      "@media (max-width: 920px)": {
        marginLeft: "-39%",
      },
      "@media (max-width: 570px)": {
        marginLeft: "-35%",
      },
      "@media (max-width: 545px)": {
        marginLeft: "-40%",
      },
      "@media (max-width: 420px)": {
        marginLeft: "-35%",
      },
      "@media (max-width: 380px)": {
        marginLeft: "-33%",
      }
    },
    tableCellwidth: {
      color: "#000",
      padding: "12px",
      fontSize: "15px",
      fontWeight: "bold",
      marginBottom:'2%',
      textAlign: "left",
      width:'400px'
    },
    tableCellLeftcustomWidth:{
      backgroundColor: "#eee",
      padding: "12px",
      fontSize: "14px",
      textAlign: "left",
      width:'600px'
    },
    tableCellLeftcustomWidth1:{
      padding: "12px",
      fontSize: "14px",
      textAlign: "left",
      width:'600px'
    },
    preventivecses:{
      marginLeft: '6% !important',
      "@media (max-width: 1025px)": {
        marginLeft: '1% !important',
      },
      "@media (max-width: 395px)": {
        marginLeft: '1% !important',
      }
    },
    headline: {
      ...title,
      fontSize: `1rem`,
      fontWeight: `400`,
      marginTop: `20px`,
    },
  };
};
export default mediaCenter;
