import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import classNames from "classnames";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.jsx";
import PropTypes from "prop-types";

class Search extends React.Component {
  state = {
    pincode: ``,
    validPincode: false,
    loaded: false
  };

  totalCount = 30;

  getMuiTheme = () =>
    createMuiTheme({
      typography: {
        useNextVariants: true
      },
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: `none`,
            width: `92%`,
            marginLeft: `3%`,
            fontSize: `15px`
          },
          responsiveScroll: {
            maxHeight: `450px !important`,
            minHeight: `450px !important`,
            overflowX: `auto`,
            "&::-webkit-scrollbar": {
              width: `7px !important`
            },
            "&::-webkit-scrollbar-track": {
              borderRadius: `10px`
            },
            "&::-webkit-scrollbar-thumb": {
              background: `#d5d6d7`,
              borderRadius: `10px`
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: `#b2b2b2`
            }
          }
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            color: `#000`,
            fontSize: `15px`
          },
          root: {
            paddingLeft: `15px !important`,
            paddingRight: `15px !important`,
            fontSize: `15px`,
            whiteSpace: `nowrap`
          }
        },
        MUIDataTableBodyRow: {
          root: {
            fontSize: `15px !important`,
            "&:nth-child(odd)": {
              backgroundColor: `rgba(0, 0, 0, 0.07)`
            },
            "&:hover": {
              backgroundColor: `transparent !important`,
              "&:nth-child(odd)": {
                backgroundColor: `rgba(0, 0, 0, 0.07) !important`
              }
            }
          }
        },
        MUIDataTableBody: {
          emptyTitle: {
            textTransform: `none !important`
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: `15px !important`,
            paddingLeft: `15px !important`,
            paddingRight: `15px !important`
          }
        },
        MUIDataTableSelectCell: {
          root: {
            display: `none`
          }
        }
      }
    });

  handleInputChange = event => {
    const testRegex = new RegExp(`\\D`);
    if (!testRegex.test(event.target.value) && event.target.value.length < 7) {
      if (event.target.value.length == 6) {
        this.setState({
          [event.target.id]: event.target.value,
          validPincode: true
        });
      } else {
        this.setState({
          [event.target.id]: event.target.value,
          validPincode: false
        });
      }
    }
  };

  handleKeyPress = event => {
    if (event.key === `Enter`) {
      event.target.blur();
    }
  };

  fetchList = async (e, pageNo, totalRecord) => {
    if (e) e.preventDefault();
    if (!pageNo) pageNo = 1;
    if (!totalRecord) totalRecord = 30;
    this.setState({
      currentPage: pageNo,
      tableRecords: totalRecord,
      serverSide: true
    });
    var places = await this.getListSuggestions();
    const { responseArr, responseList, setTotalCount } = this.props.createList(
      places,
      false
    );
    this.setState({
      list: responseArr,
      pincode: ``,
      loaded: responseList.length == 0,
      validPincode: false
    });
    if (setTotalCount) this.totalCount = 30;
  };

  getListSuggestions = async () => {
    const { apiUrl } = this.props;
    const { pincode } = this.state;
    var url, res;
    if (pincode != ``) {
      url = apiUrl + `?pincode=` + pincode;
      res = await fetch(url, {
        method: `GET`
      });
      var responseSuggestions = await res.json();
      this.totalCount = responseSuggestions.totalCount;
      return responseSuggestions;
    }
  };

  getHeaders = () => this.props.tableHeaders;

  onInputChange = (event, { newValue }) => {
    this.setState({
      [event.target.id]: newValue
    });
  };

  render() {
    const {
      classes,
      title,
      summary,
      noResultText,
      buttonData,
      searchOptions
    } = this.props;
    var options = {
      textLabels: {
        body: {
          noMatch: this.state.loaded ? noResultText : ``
        }
      },
      ...searchOptions,
      selectableRows: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      viewColumns: false,
      responsive: `scroll`,
      rowsPerPage: 30,
      rowsPerPageOptions: [30, 50, 100],
      count: this.totalCount,
      onTableChange: (action, tableState) => {
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case `changePage`:
            if (this.state.fetchApi) {
              this.fetchList(
                null,
                tableState.page + 1,
                this.state.tableRecords
              );
            }
            break;
          case `changeRowsPerPage`:
            if (this.state.fetchApi) {
              this.fetchList(
                null,
                this.state.currentPage,
                tableState.rowsPerPage
              );
            }
            break;
        }
      }
    };

    return (
      <GridContainer>
        <GridItem
          md={10}
          sm={10}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h2 className={classNames(classes.title, classes.textCenter)}>
            {title}
          </h2>
          <h4 className={classNames(classes.textCenter)}>
            <b>{summary}</b>
          </h4>
        </GridItem>
        <GridItem md={12}>
          <div className={classes.listSearchContainer}>
            <div
              style={{ backgroundColor: `rgb(229, 227, 223)`, height: `100vh` }}
            >
              <div className={classes.mapForm}>
                <GridContainer
                  className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} `}
                >
                  <GridItem sm={12} xs={12} md={12}>
                    <Card
                      blog
                      style={{ marginTop: `10px` }}
                      key={`glossary-left-${1}`}
                      className={`${classes.mrAuto} ${classes.mlAuto} ${classes.textCenter} ${classes.card}`}
                    >
                      <CardBody
                        formHorizontal
                        style={{ paddingTop: `10px`, paddingBottom: `0px` }}
                      >
                        <form onSubmit={this.fetchList}>
                          <GridContainer md={12}>
                            <GridItem md={8} sm={6}>
                              <CustomInput
                                inputProps={{
                                  id: `pincode`,
                                  placeholder: buttonData.placeholder,
                                  autocomplete: `off`
                                }}
                                value={this.state.pincode}
                                formControlProps={{
                                  fullWidth: true,
                                  className: classes.customFormControl
                                }}
                                onChange={this.handleInputChange}
                                onKeyPress={this.handleKeyPress}
                              />
                            </GridItem>
                            <GridItem md={4} sm={6}>
                              <Button
                                disabled={!this.state.validPincode}
                                block
                                color="primary"
                                className={classes.button}
                                type="submit"
                              >
                                {buttonData.cta}
                              </Button>
                            </GridItem>
                          </GridContainer>
                        </form>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
              <div style={{ color: `black`, paddingTop: `50px` }}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    key={this.totalCount}
                    data={this.state.list}
                    columns={this.getHeaders()}
                    options={options}
                  />
                </MuiThemeProvider>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}
Search.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.string,
  apiUrl: PropTypes.string,
  noResultText: PropTypes.string,
  tableHeaders: PropTypes.array,
  buttonData: PropTypes.object,
  searchFor: PropTypes.string,
  createList: PropTypes.func,
  searchOptions: PropTypes.object
};
export default withStyles(mediaCenterStyle)(Search);
