import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import classNames from "classnames";
import { graphql } from "gatsby";
import Layout from "ps-components/Layout/Layout.jsx";
import Search from "./Search.jsx";
import PropTypes from "prop-types";
import {createList} from "util/RuralCheckerUtils";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
let apiUrl = `https://customer.starhealth.in/api/portal/location/search/location`;

const CheckEligibility = ({ classes, data }) => {
  const tableHeaders = [
    {
      name: `State`,
      label: `State`,
      options: { sort: true }
    },
    {
      name: `City`,
      label: `City`,
      options: { sort: true }
    },
    {
      name: `Area`,
      label: `Area`,
      options: { sort: true }
    },
    {
      name: `Status`,
      label: `Status`,
      options: { sort: true }
    }
  ];
  const searchOptions = {
    sort: true,
    serverSide: false,
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        return (
          (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
          (order === `desc` ? 1 : -1)
        );
      });
    }
  };
  let title = `Star Micro Rural and Farmers Care Eligibility`;
  let summary = ``;
  return (
    <Layout
      image2={
        data.allStrapiImages.edges[0] &&
        data.allStrapiImages.edges[0].node &&
        data.allStrapiImages.edges[0].node.content &&
        data.allStrapiImages.edges[0].node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
    <title>Micro Rural Eligibility | StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `micro-rural-eligibility`}
      />
      <meta name="title" content="Micro Rural Eligibility | StarHealth.in" />
      <meta name="twitter:title" content="Micro Rural Eligibility | StarHealth.in" />
      <meta
        name="description"
        content="Star Micro Rural Eligibility and Farmers Care "
      />
      <meta
        name="keywords"
        content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
      />
      <meta property="og:title" content="Micro Rural Eligibility | StarHealth.in" />
      <meta property="og:description" content="Star Micro Rural Eligibility and Farmers Care " />
      <meta property="og:url" content={url + `micro-rural-eligibility`}/>
      <meta name="Micro Rural Eligibility | StarHealth.in" />
      <meta name="twitter:description" content="Star Micro Rural Eligibility and Farmers Care " />
      <meta property="twitter:url" content={url + `micro-rural-eligibility`} />
    </Helmet>
  )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div>
          <div className={classes.container}>
            <Search
              createList={createList}
              searchFor="rural care eligibility"
              title={title}
              summary={summary}
              apiUrl={apiUrl}
              noResultText="No records found"
              tableHeaders={tableHeaders}
              buttonData={{
                placeholder: `Search Pincode`,
                cta: `Check Eligibility`
              }}
              searchOptions={searchOptions}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

CheckEligibility.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object
};

export default withStyles(mediaCenterStyle)(CheckEligibility);

export const checkEligibilityQuery = graphql`
  query checkEligibilityQuery {
    allStrapiImages(filter: { category: { eq: "eligibility_check" } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
