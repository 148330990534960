export const NOT_AVAILABLE = `Not Available`;

export const validateFields = value => {
  if(value){
    return value.length > 0 ? value : NOT_AVAILABLE;
  }
  return NOT_AVAILABLE;
};

export const createList = function(places, setTotalCount) {
  let responseArr = [];
  places.map(res => {
    let temp = [];
    temp.push(validateFields(res.state));
    temp.push(validateFields(res.city));
    temp.push(validateFields(res.area));
    temp.push(validateFields(res.category) == `Rural` ? `Eligibile` : `Not Eligibile`);
    if (validateFields(res.category) == `Rural`) {
      responseArr.unshift(temp);
    } else {
      responseArr.push(temp);
    }
  });
  return {
    responseArr: responseArr,
    setTotalCount: setTotalCount,
    responseList: responseArr
  };
};